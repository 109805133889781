import React from "react";
import { useCheckCreditExpiry } from "./useCheckCreditExpiry";
import { FEATURE_KEYS } from "features/MySubscriptions/constants/MySubscriptions.constants";
import WarningIcon from "@material-ui/icons/WarningTwoTone";
import CloseIcon from "@material-ui/icons/Close";
import moduleStyle from "../ubp.module.css";
import { is_empty } from "utils/validations";
import ExlyModal from "common/Components/ExlyModal";
import { getWarningMsg } from "../ubp.utils";
import { isInternationalTimezone } from "utils/AuthUtil";

export const CheckCreditExpiry = ({ feature_key, className = "" }) => {
  const {
    showWarning,
    showExpiredModal,
    setShowWarning,
    isCreditsExpired,
    featureCreditsData,
    handleBuyMore,
    onCloseExpiredModal,
  } = useCheckCreditExpiry({ feature_key });

  if (isInternationalTimezone()) return null;
  if (is_empty(FEATURE_KEYS[feature_key])) return null;

  return (
    <>
      {showWarning ? (
        <div
          className={`d-flex ${moduleStyle.warningContainer} ${
            isCreditsExpired() ? moduleStyle.expiredContainer : ""
          } align-items-center ${className}`}
        >
          <div
            onClick={handleBuyMore}
            className={`d-flex m-auto p-2 justify-content-center pointer align-items-center`}
          >
            <WarningIcon
              className={`${moduleStyle.warningIcon} ${
                isCreditsExpired() ? moduleStyle.expiredIcon : ""
              }`}
            />
            <span className={`${moduleStyle.waringText}`}>
              {getWarningMsg(featureCreditsData)}
            </span>
          </div>
          <CloseIcon
            onClick={() => setShowWarning(false)}
            className={`mr-2 pointer ${moduleStyle.warningIcon}`}
          />
        </div>
      ) : null}

      {showExpiredModal ? (
        <ExlyModal
          open={showExpiredModal}
          showSecondaryBtn
          onSecondaryBtnClick={onCloseExpiredModal}
          onClose={onCloseExpiredModal}
          primaryBtnText="Purchase add-on"
          onPrimaryBtnClick={handleBuyMore}
          title={
            <div>
              <WarningIcon className={`mr-2 ${moduleStyle.errorIcon}`} />
              Usage limit reached
            </div>
          }
        >
          <div className="my-3">
            You have exhausted the usage limit for this feature. Purchase add-on
            credits to resume usage.
          </div>
        </ExlyModal>
      ) : null}
    </>
  );
};
