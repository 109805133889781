import React from "react";
import { is_empty } from "utils/validations";
import { CREDITS_EXPIRY_STATUS } from "features/MySubscriptions/constants/MySubscriptions.constants";
import { useDispatch, useSelector } from "react-redux";
import {
  EXLY_ACCOUNT__GET_FEATURE_CREDITS,
  EXLY_ACCOUNT__SET_FEATURE_CREDITS,
} from "../../../../ui/pages/Account/redux/actions";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { getCreditExpiryStatus } from "../ubp.utils";

export const useCheckCreditExpiry = ({ feature_key }) => {
  const dispatch = useDispatch();
  const featureCredits = useSelector((state) => state.myAccount.featureCredits);

  const [showWarning, setShowWarning] = React.useState();
  const [showExpiredModal, setShowExpiredModal] = React.useState();

  React.useEffect(() => {
    dispatch({ type: EXLY_ACCOUNT__GET_FEATURE_CREDITS, feature_key });
    setShowWarning(undefined);
    setShowExpiredModal(undefined);
  }, [feature_key]);

  React.useEffect(() => {
    const creditsData = featureCredits?.[feature_key];
    if (
      !is_empty(creditsData) &&
      (showWarning !== true || showExpiredModal !== true)
    ) {
      const creditsStatus = getCreditExpiryStatus(creditsData);
      // warning patti to be shown if consumed percent is greater that warning percent
      if (
        (creditsStatus === CREDITS_EXPIRY_STATUS.warning ||
          creditsStatus === CREDITS_EXPIRY_STATUS.expired) &&
        !is_empty(creditsData?.credits_expiry_status)
      ) {
        setShowWarning(true);
      }
      // expired popup to be shown if consumed percent is = to error percent
      if (
        creditsStatus === CREDITS_EXPIRY_STATUS.expired &&
        !is_empty(creditsData?.credits_expiry_status)
      ) {
        setShowExpiredModal(true);
      }
    }
  }, [featureCredits, feature_key]);

  const handleBuyMore = () => {
    onCloseExpiredModal();
    window.location.href = `${window.location.origin}/#/${
      app_route_ids[app_route_keys.my_subscriptions]
    }`;
  };

  const onCloseExpiredModal = () => {
    setShowExpiredModal(false);
    dispatch({
      type: EXLY_ACCOUNT__SET_FEATURE_CREDITS,
      payload: {
        data: { credits_expiry_status: null },
        feature_key,
      },
    });
  };

  const isCreditsExpired = () => {
    const creditsData = featureCredits?.[feature_key];
    const creditsStatus = getCreditExpiryStatus(creditsData);
    return creditsStatus === CREDITS_EXPIRY_STATUS.expired;
  };

  return {
    showWarning,
    setShowWarning,
    showExpiredModal,
    setShowExpiredModal,
    featureCreditsData: featureCredits?.[feature_key],
    handleBuyMore,
    onCloseExpiredModal,
    isCreditsExpired,
  };
};
