import React from "react";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { DatePicker } from "@my-scoot/component-library-legacy";
import { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-US";
import { makeStyles } from "@material-ui/core";
import { handleInputDateFormat } from "utils/Utils";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import classnames from "classnames";
import { DateRange } from "@material-ui/icons";

registerLocale("en-US", en);

const useStyles = makeStyles((theme) => ({
  popperRoot: {
    zIndex: 999,
  },
  icon: {
    color: theme?.palette?.secondary?.main,
  },
  clearIcon: {
    cursor: "pointer",
  },
  hide: {
    visibility: "hidden",
  },
}));

const ExlyDatePicker = (props) => {
  const { input, meta, isClearable, onChange, ...rest } = props;
  const { value } = input;

  const isDesktop = useMediaQuery("(min-width: 767px)");
  const classes = useStyles();

  const datePickerProps = {
    icon: (
      <>
        {isClearable && (
          <ClearRoundedIcon
            className={classnames(
              classes.icon,
              classes.clearIcon,
              !value && classes.hide
            )}
            onClick={(e) => {
              e.stopPropagation();
              input.onChange(null);
            }}
          />
        )}
        <DateRange className={classes.icon} />
      </>
    ),
  };

  return (
    <div>
      <DatePicker
        locale="en-US"
        error={meta?.error && meta?.touched}
        helperText={meta?.error && meta?.touched ? meta.error : null}
        calendarOnly={!isDesktop}
        format={(value) => (value ? moment(value).toDate() : null)}
        dateFormat={handleInputDateFormat()}
        popperClassName={classnames(classes.popperRoot, props.popoverClass)}
        {...datePickerProps}
        {...input}
        onChange={(e) => {
          if (input.onChange) {
            input.onChange(e); // final-form's onChange
          }
          if (onChange) {
            //props.onChange
            onChange(e, input.name);
          }
        }}
        {...rest}
      />
    </div>
  );
};

export default ExlyDatePicker;
