import React from "react";
import {
  RichTextEditor,
  RichTextEditorUtils,
} from "@my-scoot/component-library-legacy";
import { Box } from "@material-ui/core";
import { useStyles } from "./index.styles";
import { handleRTEImgUpload } from "features/Common/modules/File/utils/File";

const { emptyEditorState } = RichTextEditorUtils;

const ExlyRichTextEditor = (props) => {
  const classes = useStyles();

  const { input, meta, ...rest } = props;
  const { error, touched } = meta;

  return (
    <Box>
      <RichTextEditor
        error={meta.error && meta.touched}
        helperText={meta.error && meta.touched ? meta.error : null}
        editorState={input.value || emptyEditorState}
        onEditorStateChange={input.onChange}
        imageUploadCallback={handleRTEImgUpload}
        {...rest}
      />
      {error && touched && <span className={classes.error}>{error}</span>}
    </Box>
  );
};

export default ExlyRichTextEditor;
