import React from "react";
import styles from "./FormLabel.module.css";
import { checkIsStringEmpty } from "features/Common/modules/DataTypes/Strings.utils";
import classnames from "classnames";
import ExlyTooltip from "ui/modules/Tooltip";
import { templateToolTipTitle } from "ui/pages/whatsappBroadcast/create/CustomTemplates/moduleConstants";

export const FormLabel = ({
  className = "",
  textClass = "",
  text,
  required = false,
  info,
}) => {
  if (checkIsStringEmpty(text)) return null;

  return (
    <div className={classnames(`mb-1`, className)}>
      <span className={classnames(styles.labelField, textClass)}>{text}</span>
      {required && (
        <span className={`text-danger`} title="This field is required">
          *
        </span>
      )}
      {info && <ExlyTooltip text={templateToolTipTitle} />}
    </div>
  );
};
