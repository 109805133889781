import React, { useState, useCallback, useEffect } from "react";
import { Form } from "react-final-form";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Box, Dropdown, Chip } from "@my-scoot/component-library-legacy";
import { Checkbox } from "@my-scoot/component-library-legacy";
import useStyles from "../ExlyTable.styles";
import ExlyTabFilters from "./ExlyTabFilters";

import classnames from "classnames";
import {
  ALL,
  filterConstant,
  filterSourceConstants,
  getFilterTypes,
} from "./filtersConstants";
import { is_empty } from "utils/validations";
import { getParameterByName, pluralise } from "utils/Utils";
import { getCustomFiltersFromSessionStorage } from "webpage-leads/webpageHelpers";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useRefresh } from "react-admin";
import { filterTypesMapping } from "ui/pages/customers/AllCustomers/customerConstants";
import { TableActions } from "./TableActions";
import { ENTITY_TYPE } from "constants/crmConstants";

const ExlyTableFilters = ({
  setFilters,
  filterValues,
  tabFilterConfig,
  tableChipsFormatter,
  noExportButton,
  hasTabs,
  hideFilters,
  selectSize = "thin",
  onClear,
  exporter,
  rows,
  CustomFilters,
  showTopBars,
  isWebPage,
  showSelectAllcheckBox,
  wrapperClasses,
  tableActions,
  customHandleExport,
  hideAllTab,
  // todo: add all className prop in an object
  className: deprecatedClassNameProp, // These class name props are deprecated. Please use the 'classes' prop instead
  filterBoxWrapperClassName: deprecatedFilterBoxWrapperClassName, // These class name props are deprecated. Please use the 'classes' prop instead
  classes,
  defaultSelectedFilterSource, // selected filter in filters dropdown on initial load
  isEmpty,
  allValuesSelected,
  bulkActionComponent,
  entityName = ENTITY_TYPE.customer.key,
  ...rest
}) => {
  // if we want to skip the all tab then we show the first tab of the filter config else all tab
  const initialTabToShow =
    hideAllTab && !is_empty(tabFilterConfig) ? tabFilterConfig[0]?.source : ALL;

  const { onRowSelected, selected } = rest;
  const [selectedFilterSource, setSelectedFilterSource] = useState(
    defaultSelectedFilterSource
  );
  const [value, setValue] = useState(initialTabToShow);
  const [customFiltersValue, setCustomFiltersValue] = useState([]);
  const refresh = useRefresh();
  const [segmentId, setSegmentId] = useState(null);
  const isDesktop = useDesktopMediaQuery(); // should ideadlly be set here as if we don't pass it from any component it would read as false and alter styles
  // initial checks to see if select all is enabled or not
  const isChecked =
    selected?.length === rows?.length &&
    selected?.length !== 0 &&
    rows?.length !== 0
      ? true
      : false;
  const [selectAllMobile, setSelectAllMobile] = useState(isChecked);
  const filters = rest.filters
    .filter((f) => !f.hidden)
    .map((f) => ({ ...f, label: f.placeholder, value: f.source }));

  const styles = useStyles({
    isDesktop,
    hasTabs,
    hideFilters,
    selectSize,
    showSelectAllcheckBox,
  });
  const {
    noFilterPlaceholderClassName = "",
    className = "",
    filterBoxWrapperClassName = "",
    filterInputWrapperClassName = "",
  } = classes ?? {};

  useEffect(() => {
    /**
     * if only one filter item is there in the dropdown,
     * then we dont need the user to pick it. we will make it selected by default
     */
    if (filters.length === 1) {
      setSelectedFilterSource(filters[0].source);
    }
  }, [filters]);

  const clearFilters = () => {
    setFilters({});
  };

  //Separating this function for now as it shouldn't hamper other usecases
  const clearChipFilters = () => {
    setFilters({});
    refresh();
  };

  const removeFilter = (filterSource) => {
    const currentFilters = { ...filterValues };
    delete currentFilters[filterSource];
    setFilters({ ...currentFilters });
  };

  useEffect(() => {
    //If segment is already created and we have the filter we get the custom filters
    // Custom filters are saved in session storage
    // We show it only when the segment is present in filters
    const filter = JSON.parse(getParameterByName(filterConstant));
    if (filter?.segment_uid) {
      const customFiltersExisting = getCustomFiltersFromSessionStorage();
      setCustomFiltersValue(customFiltersExisting);
    } else if (is_empty(filter) && !is_empty(filterValues) && !hideAllTab) {
      clearFilters();
    }
  }, []);

  /**
   *
   * @param {*} event
   * @param {*} rows
   * Select all handller for mobile view
   */
  const handleSelectAllClick = (event, rows) => {
    const { checked } = event?.target;
    if (checked) onRowSelected(rows.map((row) => row.id));
    else onRowSelected([]);

    setSelectAllMobile(checked);
  };

  //TODO: This logic must be improved.
  const handleExport =
    exporter ||
    (() => {
      const exportBtn = document.querySelector('[aria-label="Export"]');
      if (exportBtn) {
        exportBtn.click();
      }
    });

  const selectedFilter = selectedFilterSource
    ? filters.find((f) => f.source === selectedFilterSource)
    : null;

  const renderChipLabel = useCallback(
    (filterSource) => {
      const defaultLabel = `${filterSource}: ${filterValues[filterSource]}`;
      const formattedLabel =
        tableChipsFormatter &&
        tableChipsFormatter(
          filterSource,
          filterValues[filterSource],
          selectedFilter
        );

      if (formattedLabel === null) {
        //If we want to skip chip for a filter return null from formattter
        return null;
      }

      return formattedLabel || defaultLabel;
    },
    [filterValues, tableChipsFormatter]
  );

  const chips =
    filterValues && Object.keys(filterValues).length > 0
      ? Object.keys(filterValues)
          .map((filterSource, key) => {
            const label =
              filterSource !== filterSourceConstants.segment_uid
                ? renderChipLabel(filterSource)
                : null;
            return (
              !!label && (
                <Chip
                  chipClassName={styles.chip}
                  labelClassName={styles.chipLabel}
                  index={key}
                  label={label}
                  onDelete={() => removeFilter(filterSource)}
                />
              )
            );
          })
          .filter((chip) => chip)
      : [];
  /**
   * These props are sent to custom filters as these are in the state of table filters and will also effect other table filters
   */
  const customFilterProps = {
    filterValues: value,
    setCustomFiltersValue: setCustomFiltersValue,
    customFiltersValue: customFiltersValue,
    segmentId: segmentId,
    setSegmentId: setSegmentId,
    setFilters: setFilters,
    clearFilters: clearFilters,
    selectedFilter: selectedFilter,
    hasChips: !is_empty(chips),
  };
  return (
    <Box
      className={classnames(
        styles.filterBox,
        deprecatedClassNameProp,
        className
      )}
    >
      {tabFilterConfig && tabFilterConfig.length > 0 && (
        <div className={showTopBars && !isDesktop && styles.filterTabsWrapper}>
          {" "}
          <ExlyTabFilters
            tabFilterConfig={tabFilterConfig}
            setFilters={setFilters}
            removeFilter={removeFilter}
            filterValues={filterValues}
            setValue={setValue}
            value={value}
            tabsDisabled={is_empty(customFiltersValue) ? false : true}
            wrapperClasses={wrapperClasses}
            hideAllTab={hideAllTab}
            initialTabToShow={initialTabToShow}
          />
          {!isDesktop && CustomFilters && (
            <div
              className={
                showTopBars
                  ? styles.customFiltersMobileWrapperLeads
                  : styles.customFiltersMobileWrapper
              }
            >
              {" "}
              <CustomFilters {...customFilterProps} />
            </div>
          )}
        </div>
      )}
      {hideFilters ? null : (
        <>
          <Form onSubmit={() => {}} initialValues={filterValues}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className={styles.form}>
                <div
                  className={
                    CustomFilters ? styles.fullFilterWrapper : styles.flexGrow
                  }
                >
                  <Box
                    className={classnames(
                      styles.filterPlayground,
                      deprecatedFilterBoxWrapperClassName,
                      filterBoxWrapperClassName
                    )}
                  >
                    <Box
                      className={classnames(
                        styles.filterInputWrapper,
                        filterInputWrapperClassName
                      )}
                    >
                      {selectedFilter &&
                      selectedFilter.value !== filterTypesMapping.select ? (
                        getFilterTypes({
                          ...selectedFilter,
                          setFilters,
                          filterValues,
                        })[selectedFilter?.type] || null
                      ) : (
                        <Box
                          className={classnames(
                            styles.noFilterPlaceholder,
                            noFilterPlaceholderClassName
                          )}
                        >
                          Select search By...
                        </Box>
                      )}
                    </Box>
                    <Dropdown
                      options={filters}
                      value={selectedFilterSource}
                      handleChange={setSelectedFilterSource}
                      inputBaseRootClassName={styles.searchByDropdown}
                      selectClassName={styles.select}
                      placeholder="Search By"
                      disabled={customFiltersValue?.length > 0}
                    />
                  </Box>
                  {isDesktop && CustomFilters && (
                    <CustomFilters {...customFilterProps} />
                  )}
                </div>
                {!isDesktop && bulkActionComponent}
                {showSelectAllcheckBox ||
                !noExportButton ||
                !is_empty(tableActions) ? (
                  <div className={styles.messageExportDivWrapper}>
                    <div
                      className={
                        showSelectAllcheckBox ? styles.exportBoxWrapper : ""
                      }
                    >
                      {!isDesktop && showSelectAllcheckBox && (
                        <div className={styles.checkBoxWrapperMobile}>
                          <Checkbox
                            size="large"
                            disableRipple="false"
                            wrapperClassName="mt-1"
                            checked={
                              (selectAllMobile && selected?.length) ||
                              allValuesSelected
                            }
                            onClick={(e) => handleSelectAllClick(e, rows)}
                          />
                          <div className={styles.filteredDivWrapper}>
                            {isWebPage
                              ? `Select ${"\u00A0"}Leads`
                              : `Select ${"\u00A0"}${pluralise(
                                  entityName,
                                  rows?.length
                                )}${"\u00A0"}`}
                            <span className={styles.numberOfLeads}>
                              ({rows?.length})
                            </span>
                          </div>
                        </div>
                      )}

                      {!noExportButton && (
                        <Box
                          onClick={
                            customHandleExport
                              ? () =>
                                  customHandleExport({
                                    filterValues,
                                    isEmpty,
                                  })
                              : handleExport
                          }
                        >
                          <GetAppIcon className={styles.exportBtn} />
                          {isDesktop && (
                            <span className={styles.exportText}>
                              Export Data
                            </span>
                          )}
                        </Box>
                      )}

                      {!is_empty(tableActions) ? (
                        <TableActions tableActions={tableActions} />
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </form>
            )}
          </Form>
          {chips.length > 0 && (
            <Box className={styles.chipsContainer}>
              <Box className={styles.selectedFiltersWrapper}>
                {chips}
                <Box
                  className={styles.clearFilters}
                  onClick={onClear || clearChipFilters}
                >
                  Clear Filters
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ExlyTableFilters;
